import _ from 'lodash';
import React from 'react';

import { Colors } from '@calico/calico-ui-kit';

import ChartCytoscapeNetwork from '../components/ChartCytoscapeNetwork.js';

class NetworkPage extends React.Component {
  render () {
    const baseUrl = 'https://storage.googleapis.com/storage/v1/b/calico-website-pin-public-bucket/o/';
    const filepath = 'datasets%2Fidea_regulatory_interaction_network.json?alt=media';
    const ideaColors = {
      'gene': Colors['grey-xl'],
      'go_term': '#b4b6b6',
      'invalidated': Colors['pink'],
      'validated': Colors['green'],
    };
    return (
      <section className="full-height full-width">
        <ChartCytoscapeNetwork
          url={`${baseUrl}${filepath}`}
          layout={{ name: 'preset' }}
          styles={[
            {
              selector: 'node',
              css: {
                'background-color': element => {
                  const kind = element.data('node_type');
                  if (kind === 'go') {
                    return ideaColors['go_term'];
                  }
                  const regulatorKind = element.data('regulator');
                  const hasExperiment = element.data('has_experiment');
                  if (hasExperiment && kind === 'gene') {
                    if (regulatorKind === 'invalidated') {
                      return ideaColors['invalidated'];
                    }
                    if (regulatorKind === 'validated') {
                      return ideaColors['validated'];
                    }
                    if (regulatorKind === 'predicted' || regulatorKind === 'measured') {
                      return ideaColors['gene'];
                    }
                  }
                  return '#ffffff';
                },
                'border-color': element => {
                  const kind = element.data('node_type');
                  if (kind === 'go') {
                    return ideaColors['go_term'];
                  }
                  const regulatorKind = element.data('regulator');
                  if (kind === 'gene') {
                    if (regulatorKind === 'invalidated') {
                      return ideaColors['invalidated'];
                    }
                    if (regulatorKind === 'validated') {
                      return ideaColors['validated'];
                    }
                    if (regulatorKind === 'predicted' || regulatorKind === 'measured') {
                      return ideaColors['gene'];
                    }
                  }
                },
                'border-width': 2,
                'content': element => element.data('name') || element.data('id'),
                'font-family': '"Mark OT"',
                'font-size': '6px',
                'padding': '200%',
                'shape': element => {
                  switch (element.data('node_type')) {
                    case 'go':
                      return 'round-rectangle';
                      break;
                    default:
                      return 'circle';
                  }
                },
                'text-halign': 'center',
                'text-max-width': '20px',
                'text-valign': 'center',
                'text-wrap': 'wrap',
                'height': element => {
                  if (element.data('node_type') === 'go') {
                    return 'label';
                  }
                  return '20px';
                },
                'width': element => {
                  if (element.data('node_type') === 'go') {
                    return 'label';
                  }
                  return '20px';
                },
              }
            },
            {
              selector: 'edge',
              css: {
                'arrow-scale': '1',
                'curve-style': 'bezier',
                'line-color': Colors['grey-xxl'],
                'target-arrow-color': Colors['grey-xxl'],
                'target-arrow-shape': element => {
                  if (element.data('edge_type') === 'inhibition') {
                    return 'tee';
                  }
                  if (element.data('edge_type') === 'activation') {
                    return 'triangle-backcurve';
                  }
                },
                'width': '0.5px',
              }
            },
          ]}
          style={{
            bottom: 0,
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
          }}
        />
        <aside
          className="bg-white padding-2 margin-3 margin-right-5 font-size-small absolute"
          style={{
            maxWidth: 300,
            minWidth: 100,
            width: '15vw',
            border: `1px solid ${Colors['grey-xl']}`,
            zIndex: 1001,
            right: 0,
          }}
        >
          <div className="flex-row margin-bottom-2">
            <div className="margin-right-1 text-center" style={{ width: 34 }}>
              <div
                style={{
                  background: ideaColors['gene'],
                  border: `2px solid ${ideaColors['gene']}`,
                  borderRadius: '50%',
                  display: 'inline-block',
                  height: 10,
                  width: 10,
                }}
              />
            </div>
            <div style={{ flex: 0 }}>Measured/known regulator</div>
          </div>
          <div className="flex-row margin-bottom-2">
            <div className="margin-right-1 text-center" style={{ width: 34 }}>
              <div
                style={{
                  background: 'white',
                  border: `2px solid ${ideaColors['gene']}`,
                  borderRadius: '50%',
                  display: 'inline-block',
                  height: 10,
                  width: 10,
                }}
              />
            </div>
            <div style={{ flex: 0 }}>Predicted regulator</div>
          </div>
          <div className="flex-row margin-bottom-2">
            <div className="margin-right-1 text-center" style={{ width: 34 }}>
              <div
                style={{
                  background: 'white',
                  border: `2px solid ${ideaColors['validated']}`,
                  borderRadius: '50%',
                  display: 'inline-block',
                  height: 10,
                  width: 10,
                }}
              />
            </div>
            <div style={{ flex: 0 }}>
              Predicted regulator<br />
              Validated experimentally
            </div>
          </div>
          <div className="flex-row margin-bottom-2">
            <div className="margin-right-1 text-center" style={{ width: 34 }}>
              <div
                style={{
                  background: 'white',
                  border: `2px solid ${ideaColors['invalidated']}`,
                  borderRadius: '50%',
                  display: 'inline-block',
                  height: 10,
                  width: 10,
                }}
              />
            </div>
            <div style={{ flex: 0 }}>
              Predicted regulator<br />
              Invalidated experimentally
            </div>
          </div>
          <div className="flex-row">
            <div className="margin-right-1 text-center" style={{ width: 34 }}>
              <div
                style={{
                  background: ideaColors['go_term'],
                  border: `2px solid ${ideaColors['go_term']}`,
                  borderRadius: 8,
                  display: 'inline-block',
                  height: 11,
                  width: 30,
                }}
              />
            </div>
            <div style={{ flex: 0 }}>
              GO biological<br />
              process term
            </div>
          </div>
        </aside>
      </section>
    );
  }
}

export default NetworkPage;
