export default [
  {
    nameFirst: 'Sean R.',
    nameLast: 'Hackett',
    email: 'sean@calicolabs.com',
    affiliations: [{ organization: 'Calico' }],
    corresponding: true,
  },
  {
    nameFirst: 'Edward A.',
    nameLast: 'Baltz',
    email: 'eabaltz@google.com',
    affiliations: [{ organization: 'Google AI' }],
    corresponding: true,
  },
  {
    nameFirst: 'Marc',
    nameLast: 'Coram',
    email: 'mcoram@google.com',
    affiliations: [{ organization: 'Google AI' }],
    corresponding: true,
  },
  {
    nameFirst: 'Bernd J.',
    nameLast: 'Wranik',
    email: null,
    affiliations: [{ organization: 'Calico' }],
  },
  {
    nameFirst: 'Griffin',
    nameLast: 'Kim',
    email: null,
    affiliations: [{ organization: 'Calico' }],
  },
  {
    nameFirst: 'Adam',
    nameLast: 'Baker',
    email: 'baker@calicolabs.com',
    affiliations: [{ organization: 'Calico' }],
  },
  {
    nameFirst: 'Minjie',
    nameLast: 'Fan',
    email: null,
    affiliations: [{ organization: 'Google AI' }],
  },
  {
    nameFirst: 'David G.',
    nameLast: 'Hendrickson',
    email: null,
    affiliations: [{ organization: 'Calico' }],
  },
  {
    nameFirst: 'Marc',
    nameLast: 'Berndl',
    email: null,
    affiliations: [{ organization: 'Google AI' }],
  },
  {
    nameFirst: 'R. Scott',
    nameLast: 'McIsaac',
    email: 'rsm@calicolabs.com',
    affiliations: [{ organization: 'Calico' }],
    corresponding: true,
  },
];
