import _ from 'lodash';
import DocumentTitle from 'react-document-title';
import React from 'react';
import { LinkExternal } from '@calico/calico-ui-kit';
import { GcsFileViewer } from '@calico/calico-ui-editorial';

import withTranscriptionFactorData from '../components/withTranscriptionFactorData.js';

const PUBLIC_BASE_URL = 'https://storage.googleapis.com/calico-website-pin-public-bucket/';

class TranscriptionFactorsPage extends React.Component {
  state = { selectedGeneSymbol: '' }

  componentWillReceiveProps (nextProps: Object) {
    if (_.isEmpty(this.props.genes) && !_.isEmpty(nextProps.genes)) {
      this.selectGene(_.sample(nextProps.genes));
    }
  }

  selectGene = (geneSymbol: string) => {
    this.setState({ selectedGeneSymbol: geneSymbol });
    const { trackEvent } = this.props;
    if (_.isFunction(trackEvent)) {
      trackEvent({
        category: 'Exploration',
        action: 'View TF effects',
        label: geneSymbol,
      });
    }
  }

  render () {
    const { filesByGene, genes } = this.props;
    if (_.isEmpty(genes)) {
      return null;
    }
    const { selectedGeneSymbol } = this.state;
    const title = !_.isEmpty(selectedGeneSymbol) ? `${selectedGeneSymbol} | Perturbation inference of networks` : 'Transcription factors | Perturbation inference of networks';
    const files = filesByGene[selectedGeneSymbol] || [];
    const vizByKind = _.keyBy(this.props.visualizationKinds, 'kind');
    return (
      <DocumentTitle title={title}>
        <div className="article content-width">
          <section>
            <h2 className="font-size-2">
              {!_.isEmpty(selectedGeneSymbol) &&
                <span>
                  <span>Effects of inducing </span>
                  <select
                    className="font-size-2 margin-left-1 cursor-pointer"
                    onChange={event => this.selectGene(event.target.value)}
                    value={selectedGeneSymbol}
                  >
                    {genes.map((geneSymbol, g) => {
                      return (
                        <option key={geneSymbol + g} value={geneSymbol}>
                          {geneSymbol}
                        </option>
                      );
                    })}
                  </select>
                  <LinkExternal
                    className="margin-left-3 font-size-small smooth normal"
                    to={`https://www.yeastgenome.org/locus/${selectedGeneSymbol.toLowerCase()}`}
                    title={`SGD page for ${selectedGeneSymbol}`}
                  />
                </span>
              }
            </h2>
            
            {!_.isEmpty(selectedGeneSymbol) &&
              <div className="padding-right-5">
                <p className="font-size-small smooth grey-l margin-bottom-3">
                  N.B. Multiple timecourse experiments were conducted for several of the transcription factors. In those instances, multiple sets of similar visualizations will be presented.
                </p>
                
                <h4>{vizByKind['targetExpression'].displayName}</h4>
                <p className="max-line-length">{vizByKind['targetExpression'].description}</p>
                <GcsFileViewer bucketUrl={PUBLIC_BASE_URL} files={_.filter(files, { kind: 'targetExpression' })} />

                {/* MOTIFS */}

                <h4>{vizByKind['attributionNetwork'].displayName}</h4>
                <p className="max-line-length">{vizByKind['attributionNetwork'].description}</p>
                <GcsFileViewer bucketUrl={PUBLIC_BASE_URL} files={_.filter(files, { kind: 'attributionNetwork' })} />

                <h4>{vizByKind['attributionBreakdown'].displayName}</h4>
                <p className="max-line-length">{vizByKind['attributionBreakdown'].description}</p>
                <GcsFileViewer bucketUrl={PUBLIC_BASE_URL} files={_.filter(files, { kind: 'attributionBreakdown' })} />
              </div>
            }
          </section>
        </div>
      </DocumentTitle>
    );
  }
}

export default withTranscriptionFactorData(TranscriptionFactorsPage);
