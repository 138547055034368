import _ from 'lodash';
import React from 'react';
import qs from 'query-string';

const getComponentName = (Component: Object) => {
  return `${Component.displayName || Component.name || 'Component'}`;
}

const BASE_URL = 'https://www.googleapis.com/storage/v1/b/calico-website-pin-public-bucket';

const VISUALIZATION_KINDS = [
  {
    kind: 'attributionBreakdown',
    displayName: 'Attribution breakdown',
    description: 'For each gene differentially expressed after initial TF induction, this visualization shows the fraction of downstream expression change attributed to each transcription factor in the model-predicted transcriptional network.',
    filename: 'attribution_barplot',
  },
  {
    kind: 'attributionNetwork',
    displayName: 'Attribution network',
    description: 'This visualization shows the model-predicted transcriptional network, if available. The scatter plot shows downstream expression of differentially expressed genes after the transcription factor was induced. Points are colored by the transcription factor in the predicted network that the expression is attributed to.',
    filename: 'attribution_scatterplot',
  },
  {
    kind: 'motifs',
    displayName: 'Motif data',
    description: 'TK.',
    filename: null,
  },
  {
    kind: 'targetExpression',
    displayName: 'Target expression effects',
    description: 'This animation shows the fold change in downstream gene expression after the specified transcription factor was induced. Time (t) = minutes after induction.',
    filename: 'expression',
  },
];

function withTranscriptionFactorData (WrappedComponent: Object) {
  class WithTranscriptionFactorData extends React.Component {
    state = { error: null, filesByGene: [], folders: [], }

    componentDidMount () {
      this.fetchFolders();
      this.fetchFilepaths();
    }

    fetchFolders = async () => {
      const params = qs.stringify({
        prefix: 'data/',
        delimiter: '/',
      });
      try {
        const response = await fetch(`${BASE_URL}?${params}`);
        if (response) {
          const data = await response.json();
          const folders = _.get(data, 'prefixes', []);
          this.setState({ folders });
        }
      }
      catch (error) {
        this.setState({ error });
      }
    }

    fetchFilepaths = async () => {
      const filesPdf = await this.fetchFilepathsByFiletype('pdf');
      const filesGif = await this.fetchFilepathsByFiletype('gif');
      const filepaths = [...filesPdf, ...filesGif];
      
      const kindsByFilename = _.keyBy(this.props.visualizationKinds, 'filename');
      const possibleFilenames = _.keys(kindsByFilename).filter(n => n !== 'null');
      const getKindFromFilename = (filename: string) => {
        let kind = null;
        _.forEach(possibleFilenames, possibleName => {
          if (filename.endsWith(possibleName)) {
            kind = kindsByFilename[possibleName].kind;
          }
        });
        return kind;
      }

      const filesByGene = _.reduce(filepaths, (result, filepath) => {
        if (_.isString(filepath)) {
          const fullFilename = _.last(filepath.split('/'));
          const [filename, filetype] = fullFilename.split('.');
          const gene = _.first(filename.split('_'));
          const kind = getKindFromFilename(filename);
          result[gene] = _.concat(result[gene] || [], {
            filename,
            filepath,
            filetype,
            kind,
          });
        }
        return result;
      }, {});
      this.setState({ filesByGene });
    }

    fetchFilepathsByFiletype = async (suffix: string) => {
      const params = qs.stringify({ delimiter: `.${suffix}` });
      try {
        const response = await fetch(`${BASE_URL}/o/?${params}`);
        if (response) {
          const data = await response.json();
          const filepaths = _.get(data, 'prefixes', []);
          return filepaths;
        }
      }
      catch (error) {
        this.setState({ error });
      }
    }

    render () {
      const { filesByGene } = this.state;
      const genes = (_.keys(filesByGene) || []).sort();
      return (
        <WrappedComponent
          filesByGene={filesByGene}
          genes={genes}
          visualizationKinds={this.props.visualizationKinds}
          {...this.props}
        />
      );
    }
  }

  WithTranscriptionFactorData.defaultProps = {
    visualizationKinds: VISUALIZATION_KINDS,
  };

  WithTranscriptionFactorData.displayName = `withTranscriptionFactorData(${getComponentName(WrappedComponent)})`;
  
  return WithTranscriptionFactorData;
}

export default withTranscriptionFactorData;
