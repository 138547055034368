import Iframe from 'react-iframe';
import React from 'react';
import { cx } from 'emotion';
import { Colors, ProgressIndicator } from '@calico/calico-ui-kit';

const HEATMAP_SHINY_URL = 'http://35.236.111.75:8787/yeast_induction/';

const HeatmapsPage = ({ className, style }) => {
  return (
    <div className={cx('relative full-height full-width', className)} style={style}>
      <div className="full-height full-width fill">
        <div className="content-width">
          <h3 className="grey-l normal padding-top-5">Loading heatmaps (this may take a moment)</h3>
          <ProgressIndicator block className="font-size-7 grey-xl padding-bottom-6" dots />
        </div>
      </div>
      <div>
        <Iframe
          height="100vh"
          styles={{ paddingLeft: 15, paddingRight: 15 }}
          url={HEATMAP_SHINY_URL}
        />
      </div>
    </div>
  );
}

export default HeatmapsPage;
