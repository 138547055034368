import isEqual from 'fast-deep-equal';
import React from 'react';
import { cloneDeep, isEmpty } from 'lodash';
import { ReactCytoscape } from 'react-cytoscape';

import { Colors } from '@calico/calico-ui-kit';

class ChartCytoscapeNetwork extends React.Component {
  state = { isReadyToRender: false };

  componentDidMount () {
    this.setup();
  }

  shouldComponentUpdate (nextProps: Object, nextState: Object) {
    const shouldAlwaysUpdate = this.props.shouldAlwaysUpdate;
    const elementsAreDifferent = !isEqual(this.state.elements, nextState.elements);
    const styleIsDifferent = !isEqual(this.props.style, nextProps.style);
    const newVersion = this.props.version !== nextProps.version;
    if (shouldAlwaysUpdate || elementsAreDifferent || styleIsDifferent || newVersion) {
      return true;
    }
    return false;
  }

  cytoscapeRef = (cytoscapeInstance: Object) => {
    this.cy = cytoscapeInstance;
    cytoscapeInstance.on('tap', 'node', e => {
      const element = e.target;
      this.props.onSelectNode && this.props.onSelectNode(element.data());
    });
  }

  setup = async () => {
    let { elements, url } = this.props;
    if (url) {
      elements = [];
      try {
        const response = await fetch(url);
        if (response.ok) {
          const result = await response.json();
          if (!isEmpty(result)) {
            if (result.elements) {
              elements = cloneDeep(result.elements);
            }
          }
        }
      }
      catch (error) {
        console.log(error);
      }
    }
    this.setState({
      elements,
      isReadyToRender: true,
    });
  } 

  render () {
    const {
      elements,
      isReadyToRender,
    } = this.state;
    const {
      layout,
      options,
      style,
      styles,
    } = this.props;
    if (!isReadyToRender) {
      return null;
    }
    return (
      <ReactCytoscape
        containerID="cy"
        cyRef={cytoscapeInstance => { this.cytoscapeRef(cytoscapeInstance) }}
        cytoscapeOptions={{
          wheelSensitivity: 0.1,
          ...options,
        }}
        elements={elements}
        layout={layout}
        styleContainer={style}
        style={[
          {
            selector: 'core',
            css: {
              'active-bg-color': Colors['blue'],
            }
          },
          {
            selector: 'node',
            css: {
              'background-color': Colors['grey-xl'],
              'content': element => element.data('name') || element.data('id'),
              'font-family': '"Mark OT"',
              'text-halign': 'center',
              'text-valign': 'center',
            }
          },
          // {
          //   selector: '$node > node',
          //   css: {
          //     'background-color': Colors['grey-xl'],
          //     'padding-bottom': '10px',
          //     'padding-left': '10px',
          //     'padding-right': '10px',
          //     'padding-top': '10px',
          //     'text-halign': 'center',
          //     'text-valign': 'top',
          //   }
          // },
          {
            selector: 'edge',
            css: {
              'curve-style': 'bezier',
              'line-color': Colors['grey-xxl'],
              'target-arrow-shape': 'triangle',
              'width': '1px',
            }
          },
          ...styles,
        ]}
      />
    );
  }
}

ChartCytoscapeNetwork.defaultProps = {
  elements: [],
  layout: { name: 'cola' },
  onSelectNode: null,
  options: {},
  shouldAlwaysUpdate: false,
  styles: [],
  url: null,
  version: 0,
};

export default ChartCytoscapeNetwork;
