import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
import React from 'react';
import DocumentTitle from 'react-document-title';
import { Colors } from '@calico/calico-ui-kit';
import {
  AuthorList,
  CalicoResearchFooter,
  CalicoResearchHeader,
  Citation,
  Navigation,
  ShareButtons,
  Title,
} from '@calico/calico-ui-editorial';

import Authors from './content/Authors';
import DownloadsPage from './pages/DownloadsPage';
import HeatmapsPage from './pages/HeatmapsPage';
import HomePage from './pages/HomePage';
import KineticFitsPage from './pages/KineticFitsPage';
import NetworkPage from './pages/NetworkPage';
import TranscriptionFactorsPage from './pages/TranscriptionFactorsPage';
import withAnalytics from './components/withAnalytics';

const ComposedDownloadsPage = withRouter(withAnalytics(DownloadsPage));
const ComposedHeatmapsPage = withRouter(withAnalytics(HeatmapsPage));
const ComposedHomePage = withRouter(withAnalytics(HomePage));
const ComposedKineticFitsPage = withRouter(withAnalytics(KineticFitsPage));
const ComposedNetworkPage = withRouter(withAnalytics(NetworkPage));
const ComposedTranscriptionFactorsPage = withRouter(withAnalytics(TranscriptionFactorsPage));

const CITATION = 'Learning causal networks using inducible transcription factors and transcriptome‐wide time series. Sean R. Hackett, Edward A. Baltz, Marc Coram, Bernd J. Wranik, Griffin Kim, Adam Baker, Minjie Fan, David G. Hendrickson, Marc Berndl, R. Scott McIsaac. Mol Syst Biol (2020)16:e9174. doi: https://doi.org/10.15252/msb.20199174';
const CITATION_TITLE = 'Learning causal networks using inducible transcription factors and transcriptome‐wide time series.';
const DOI = 'https://doi.org/10.15252/msb.20199174';
const RESEARCH_TITLE = 'IDEA: Induction dynamics gene expression atlas';
const WEBSITE_URL = 'http://idea.research.calicolabs.com';

// Temporary links to Shiny apps that can't be embedded in iFrames because they're insecure;
// instead we open these in a new tab.
const KINETIC_FITS_SHINY_URL = 'http://35.236.111.75:8787/parameter_scatter/';
const HEATMAP_SHINY_URL = 'http://35.236.111.75:8787/yeast_induction/';

class App extends React.Component {
  state = {
    calicoResearchHeaderBottom: 0,
    scrollTop: 0,
    titleBottom: 0,
  };

  updateScroll = (event: Object) => {
    this.setState({ scrollTop: event.target.scrollTop });
  }

  render () {
    const { calicoResearchHeaderBottom, scrollTop, titleBottom } = this.state;
    const isScrolledUp = scrollTop >= titleBottom;
      
    const renderNavigationComponent = (navigationStyle: Object, shouldDrawBackgroundAndBorder: boolean) => (
      <Navigation
        className="content-width"
        items={[
          {
            name: 'Home',
            path: '/'
          },
          {
            name: 'Downloads',
            path: '/data'
          },
          {
            name: 'Induction heatmaps',
            content: <a href={HEATMAP_SHINY_URL} target="_blank">Induction heatmaps</a>,
          },
          {
            name: 'Kinetic fits explorer',
            content: <a href={KINETIC_FITS_SHINY_URL} target="_blank">Kinetic fits explorer</a>,
          },
          {
            name: 'Predicted network',
            path: '/network'
          },
          {
            name: 'TF effects',
            path: '/tf'
          },
        ]}
        style={{
          backgroundColor: shouldDrawBackgroundAndBorder && Colors['grey-xxxl'],
          borderBottom: '1px solid',
          borderBottomColor: shouldDrawBackgroundAndBorder ? Colors['grey-xl'] : 'rgba(255,255,255,0)',
          top: calicoResearchHeaderBottom,
          transition: 'border-color .2s',
          ...navigationStyle,
        }}
      />
    );

    const renderInFlow = (children: Array<Object>) => {
      return (
        <main
          className="relative app flex-column"
          id="app"
          onScroll={this.updateScroll}
          style={{
            height: '100vh',
            overflowX: 'hidden',
            overflowY: 'scroll',
          }}
        >
          <CalicoResearchHeader
            onMeasureElement={rect => this.setState({ calicoResearchHeaderBottom: rect.bounds.bottom })}
            subheader={RESEARCH_TITLE}
            showSubheader={scrollTop > titleBottom - calicoResearchHeaderBottom}
          />
          <Title
            onMeasureElement={rect => this.setState({ titleBottom: rect.bounds.bottom })}
            scrollTop={scrollTop}
            title={RESEARCH_TITLE}
          />
          {renderNavigationComponent({}, isScrolledUp)}
          <section className="flex-1" style={{ minHeight: '150vh' }}>
            {children}
            <CalicoResearchFooter />
          </section>
        </main>
      );
    };

    const renderInFixed = (children: Array<Object>) => {
      const shouldDrawBackgroundAndBorder = true;
      return (
        <main
          className="relative app flex-column"
          id="app"
          onScroll={this.updateScroll}
          style={{
            height: '100vh',
            overflowX: 'hidden',
            overflowY: 'hidden',
          }}
        >
          <CalicoResearchHeader
            onMeasureElement={rect => this.setState({ calicoResearchHeaderBottom: rect.bounds.bottom })}
            subheader={RESEARCH_TITLE}
            showSubheader={true}
          />
          {renderNavigationComponent({ marginBottom: 0 }, shouldDrawBackgroundAndBorder)}
          <section className="flex-1 relative" style={{ overflowX: 'scroll' }}>
            {children}
          </section>
          {/*<CalicoResearchFooter />*/}
        </main>
      );
    };

    return (
      <DocumentTitle title={`${RESEARCH_TITLE} | Calico research`}>
        <div>
          <Router basename={process.env.PUBLIC_URL}>
            <Switch>
              <Route
                exact
                path="/"
                render={() => renderInFlow([
                  <section className="content-width font-size-small smooth">
                    <Citation
                      citation={CITATION}
                      className="margin-bottom-2 bold"
                      uri={DOI}
                      title={CITATION_TITLE}
                      showCopyUri
                      showCopyCitation
                      style={{ lineHeight: 1.35 }}
                    />
                    <AuthorList authors={Authors} showContact contactSubject={RESEARCH_TITLE} />
                  </section>,
                  <ComposedHomePage />
                ])}
              />
              <Route
                exact
                path="/data"
                render={() => renderInFlow([<ComposedDownloadsPage />])}
              />
              <Route
                exact
                path="/heatmaps"
                render={() => renderInFixed([<ComposedHeatmapsPage />])}
              />
              <Route
                exact
                path="/kinetics"
                render={() => renderInFixed([<ComposedKineticFitsPage />])}
              />
              <Route
                path="/tf/:geneSymbol?"
                render={() => renderInFlow([<ComposedTranscriptionFactorsPage />])}
              />
              <Route
                exact
                path="/network"
                render={() => renderInFixed([<ComposedNetworkPage />])}
              />
            </Switch>
          </Router>
        </div>
      </DocumentTitle>
    );
  }
}

export default App;
